import * as React from "react"
import styled from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { Link } from 'gatsby-plugin-react-i18next'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Button from './Button'
import { Testimonial } from './Testimonial'
import { media } from './MediaQueries'
import { Grid, Cell } from "styled-css-grid"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import LogoWhite from '../images/logo-white.svg'
import { Image } from '../components/Images'

import { handleClick } from "../utils/handleClick"

const Footer = ({ content, page, bgColor, dark, noTestimonial }) => {
    const data = useStaticQuery(graphql`
        query{
            allWpPost(sort: { fields: [date], order: DESC }, limit: 3) {
                nodes {
                    title
                    excerpt
                    slug
                    excerpt
                    uri
                    date(formatString: "DD MMMM YYYY", locale: "pt")
                    featuredImage {
                        node {
                            altText
                            localFile {
                                childImageSharp {
                                    gatsbyImageData(
                                        placeholder: BLURRED
                                        layout: FULL_WIDTH
                                        formats: [AUTO, WEBP, JPG]
                                        quality: 100
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    
    const breakpoints = useBreakpoint()

    const d = new Date();
    let year = d.getFullYear();

    return (
        <FooterStyled>
            {!noTestimonial &&
                <Testimonial
                    content={content.testimonial}
                    bgColor={bgColor}
                    dark={dark}
                />
            }
            <footer>
                <BlogStyled>
                    {page !== 'blog' &&
                        <>
                            <div className='title'>
                                <h1>{content.blog.title}</h1>
                                <hr />
                                <Button
                                    link={content.blog.link}
                                    text={content.blog.button}
                                    dark
                                />
                            </div>
                            <div className='news-container'>
                                <Grid columns={breakpoints.m ? 1 : 6} gap={breakpoints.m ? '15vw' : '4vw'}>
                                    {data.allWpPost.nodes.map((node, i) => (
                                        <Cell width={2} className='news' key={i}>
                                            <Link to={'/blog' + node.uri}>
                                                {getImage(node.featuredImage?.node?.localFile) &&
                                                    <GatsbyImage image={getImage(node.featuredImage?.node?.localFile)} alt={node.featuredImage?.node?.altText || ''} />
                                                }
                                                <h2>{node.title}</h2>
                                                <span>{node.date}</span>
                                            </Link>
                                            <hr />
                                        </Cell>
                                    ))}
                                </Grid>
                            </div>
                        </>
                    }
                    <hr />
                </BlogStyled>
                <BottomStyled>
                    {/** Logo  - first row */}
                    <Grid columns={breakpoints.m ? 1 : 6} gap={breakpoints.m ? '2rem' : '2%'} className='logo'>
                        <Cell width={1} center={breakpoints.m ? true : false} middle>
                            <Link to='/' className='logo-link'>
                                <LogoWhite />
                            </Link>
                        </Cell>
                    </Grid>
                    {/** 2nd row */}
                    <Grid columns={breakpoints.m ? 1 : 6} gap={breakpoints.m ? '2rem' : '2%'} >
                        <Cell width={breakpoints.m ? 1 : 2} center={breakpoints.m ? true : false} middle className='mt-1 '>
                            <div>
                                <p style={{ color: "white" }}>{content.siganos.title}</p>
                            </div>
                            <div className="social-icons">
                                {content.social.map((social, i) => (
                                    <a href={social.link} target='_blank' rel='noreferrer' key={i}>
                                        <Image src={social.img} alt={social.alt} />
                                    </a>
                                ))}
                            </div>
                        </Cell>
                        <Cell width={1} center={breakpoints.m ? true : false} middle className='mt-1 links-container'>
                            {content.pages.map((pages, i) => (
                                <Link to={pages.link} key={i}>{pages.text}</Link>
                            ))}
                        </Cell>
                        <Cell width={1} center={breakpoints.m ? true : false} middle className='mt-1 links-container'>
                            <a  href={content.contacts.address.link}>
                                {content.contacts.address.text.map((item, i) => (
                                    <p key={i}>{item}</p>
                                ))}
                            </a>
                            <a href={"mailto:" + content.contacts.email}>
                                <p>{content.contacts.email}</p>
                            </a>
                            <a onClick={() => handleClick(content.contacts.tel)}>
                                <p>{content.contacts.teldisplay}</p>
                            </a>
                            <small style={{ fontSize: "7px", marginTop: "-8px" }}>{content.contacts.txt}</small>
                        </Cell>
                        <Cell width={1} center={breakpoints.m ? true : false} middle className='mt-1 links-container'>
                            <a href={content.contacts.showroom.link} target="_blank">
                                {content.contacts.showroom.text.map((item, i) => (
                                    <p key={i}>{item}</p>
                                ))}
                            </a>
                            <a href={"mailto:" + content.contacts.showroom.email}>
                                <p>{content.contacts.showroom.email}</p>
                            </a>
                            <a  onClick={() => handleClick(content.contacts.showroom.tel)}>
                                <p>{content.contacts.showroom.teldisplay}</p>
                            </a>
                            <small style={{ fontSize: "7px", marginTop: "-8px" }}>{content.contacts.showroom.txt}</small>
                        </Cell>
                        <Cell width={1} center={breakpoints.m ? true : false} middle className='mt-1 links-container'>
                            {content.terms.map((item, i) => (
                                <Link to={item.link} key={i}> {item.text}</Link>
                            ))}
                        </Cell>
                    </Grid>
                    {/** 3rd row */}
                    <Grid columns={breakpoints.m ? 1 : 6} gap={breakpoints.m ? '2rem' : '2%'} className='mt-3'>
                        {breakpoints.l ? <></> :
                            <Cell width={1} center={breakpoints.m ? true : false} middle>
                            </Cell>
                        }
                        {breakpoints.l ? <></> :
                            <Cell width={1} center={breakpoints.m ? true : false} middle>
                            </Cell>
                        }

                        <Cell width={1} center={breakpoints.m ? true : false} middle  >
                            <a href='ficha-pt.pdf' target='_blank' className='pt2020'>
                                <Image src='pt2020.png' alt='pt2020' />
                            </a>
                            <a href='ficha-prr.pdf' target='_blank' className='prr'>
                                <Image src='prr.png' alt='prr' />
                            </a>
                            <a href='ficha-centro2030.pdf' target='_blank' className='centro2030'>
                                <Image src='centro2030.png' alt='centro2030' />
                            </a>
                        </Cell>
                        <Cell width={1} center={breakpoints.m ? true : false} middle className="links-container" style={{ justifyContent: "center" }}>
                            {content.copyright.map((copyright, i) => (
                                copyright.outbound ?
                                    <a href={copyright.link} target='_blank' rel='noreferrer' key={i}>{copyright.text}</a>
                                    :
                                    <Link to={copyright.link} key={i}>{copyright.text}
                                        {year}
                                    </Link>
                            ))}
                        </Cell>
                        <Cell width={1} center={breakpoints.m ? true : false} middle>
                            <div className=" pme">
                                <Image src='footer/pme-excelencia.png' alt='PME' />
                            </div>
                        </Cell>
                        <Cell width={1} center={breakpoints.m ? true : false} middle>
                            <div className=" livro-reclamacoes">
                                <a href='https://www.livroreclamacoes.pt/Inicio/' target='_blank'>
                                    <Image src='footer/livro-reclamacoes-logo.png' alt='Livro de Reclamações' />
                                </a>
                            </div>
                        </Cell>

                    </Grid>


                </BottomStyled>
            </footer>
        </FooterStyled>
    )
}

export default Footer

const FooterStyled = styled.div`
    background-color:#1b1c22;

    a{
        cursor:pointer;
    }

    footer{
        max-width:1920px;
        margin:0 auto;
        padding: clamp(30px, 5vw, 60px) 5%;
    }
`

const BlogStyled = styled.div`
    .title{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        ${media.s`
            flex-direction:column;
            gap: 1rem;
            margin-bottom: 1rem;
        `}

        h1{
            font-weight:100;
            color:#eeefed;
            font-size: 2.5rem;
            text-transform:uppercase;
        }

        hr{
            border-color:#e8eae5;
            width:50vw;
            margin-left: auto;
            margin-right: 2rem;

            ${media.l`
                margin-left:2rem;
            `}

            ${media.s`
                display:none;
            `}
        }
    }

    .news-container {
        padding-top: clamp(30px, 5vw, 60px);
        padding-bottom: clamp(30px, 5vw, 60px);

        ${media.m`
            flex-wrap:wrap;
            gap:3rem;
        `}

        .news{
            position:relative;

            .gatsby-image-wrapper{
            }

            a{
                display: flex;
                flex-direction: column;
                gap: 2rem;
            }

            h2{
                font-family: 'Cabin', sans-serif;
                letter-spacing: 2px;
                text-transform: uppercase;
                color: #eeefed;
                font-size:1rem;
                max-width:400px;
            }

            span{
                font-family:'Exo', sans-serif;
                font-weight:bold;
                color: #eeefed;
                text-transform:uppercase;
                font-size:0.7rem;
            }

            hr{
                position: absolute;
                height: 100%;
                top: 0;
                left: -7.8%;
                margin:0;
                border-color:#e8eae5;

                :first-child{
                    display:none;
                }
            }

            :first-child hr{
                display:none;
            }
        }
    }

    hr{
        border-color:#e8eae5;
        margin:0;
    }
`

const BottomStyled = styled.div`
    padding-top: clamp(30px, 5vw, 60px);
    @media (max-width: 700px){
        div{
            margin-left: auto;
            margin-right: auto;
        }
    }
    .mt-1{
        margin-top: 1em;
    }
    .mt-3{
        margin-top: 3em;
    }

    .pt2020{
        max-width: 400px;
        margin-left: auto;
        width: 100%;

        ${media.m`
            margin-left:unset;
            margin:0 auto;
            min-width: 50vw;
        `}
    }
    .prr{
        max-width: 400px;
        margin-left: auto;
        margin-top: 10px;
        width: 100%;

        ${media.m`
            margin-left:unset;
            margin:0 auto;
            min-width: 50vw;
            margin-top: 3vh;
        `}
    }
    .centro2030{
        max-width: 400px;
        margin-left: auto;
        margin-top: 10px;
        width: 100%;

        ${media.m`
            margin-left:unset;
            margin:0 auto;
            min-width: 50vw;
            margin-top: 3vh;
        `}
    }
    .centro2030 .image{
        width: 100%;
        height: 100%;
    }

    .centro2030 .image img{
        object-fit: contain !important;
    }

    .social-icons{
        display: flex;
        gap: 1rem;
        align-items: center;
        margin-top:1rem;

        a{
            display:flex;

            img{
                width:20px;
                height:20px;
            }
        }
    } 
    
    .livro-reclamacoes{
        .image{
            width:110px;
            margin-right:auto;

            ${media.m`
                margin-right:unset;
            `}

            ${media.s`
                max-width:125px;
            `}
        }
    }

    .pme {

        .image{
            width:39px;
            margin-left:auto;

            ${media.m`
                margin-right:unset;
            `}

            ${media.s`
                max-width:125px;
            `}
        }
    }

    .logo{

        .logo-link{
            display:flex;
            height:inherit;

            ${media.m`
                display:block;
            `}
        }

        svg{
            max-width:250px;
            width: 100%;
            height: 100%;
            ${media.m`
                margin:0 auto;
                max-width:200px;
            `}
        }
    }

    .links-container{
        color: #eeefed;
        letter-spacing: 1px;
        font-family:'Cabin', sans-serif;
        font-size: 0.8rem;
        line-height: 20px;
        white-space:nowrap;
        text-transform:uppercase;
        -webkit-box-pack: start;
        justify-content: start;

        ${media.m`
            text-align:center;
        `}

        ${media.s`
            white-space: break-spaces;
        `}

        .copyright, .column{
            display:flex;
            flex-direction:column;
        }

        a{
            color:#eeefed;
        }
    }
    
`